export default function plugin(API) {
    const calcBMI = (weight, height) => {
        weight = parseInt(weight);
        height = parseInt(height) / 100;
        let bmi = weight / (height * height);

        bmi = Math.round(bmi * 100) / 100;

        return bmi;
    };

    API.calculateBMI = (selector) => {
        const weightInput = selector.querySelector('#bmi-weight');
        const heightInput = selector.querySelector('#bmi-height');
        const bmiResult = selector.querySelector('#bmi-results');

        selector.addEventListener('submit', (e) => {
            e.preventDefault();

            let bmi = calcBMI(weightInput.value, heightInput.value);

            bmiResult.innerHTML = '';

            if (bmi < 13)
                bmiResult.innerHTML = `<span class="notification is-danger">Ihr BMI Wert ist <strong>${bmi}</strong>, lebensbedrohliches Untergewicht</span>`;
            if (bmi >= 13 && bmi < 18)
                bmiResult.innerHTML = `<span class="notification is-warning">Ihr BMI Wert ist <strong>${bmi}</strong>, leistungsminderndes Untergewicht</span>`;
            if (bmi >= 18 && bmi < 20)
                bmiResult.innerHTML = `<span class="notification is-success">Ihr BMI Wert ist <strong>${bmi}</strong>, austrainierter Marathonläufer</span>`;
            if (bmi >= 20 && bmi < 22)
                bmiResult.innerHTML = `<span class="notification is-success">Ihr BMI Wert ist <strong>${bmi}</strong>, Normalgewicht eines Fitneßbewußten</span>`;
            if (bmi >= 22 && bmi < 25)
                bmiResult.innerHTML = `<span class="notification is-success">Ihr BMI Wert ist <strong>${bmi}</strong>, Normalgewicht</span>`;
            if (bmi >= 25 && bmi < 30)
                bmiResult.innerHTML = `<span class="notification is-warning">Ihr BMI Wert ist <strong>${bmi}</strong>, Übergewicht</span>`;
            if (bmi >= 30 && bmi < 35)
                bmiResult.innerHTML = `<span class="notification is-danger">Ihr BMI Wert ist <strong>${bmi}</strong>, Fettleibigkeit (Adipositas I)</span>`;
            if (bmi >= 35 && bmi < 40)
                bmiResult.innerHTML = `<span class="notification is-danger">Ihr BMI Wert ist <strong>${bmi}</strong>, starke Fettleibigkeit (Adipositas II)</span>`;
            if (bmi >= 40)
                bmiResult.innerHTML = `<span class="notification is-danger">Ihr BMI Wert ist <strong>${bmi}</strong>, extreme Fettleibigkeit (Adipositas III)</span>`;

            return bmiResult;
        });
    };
}
