/**
 *
 * @param {*} API
 */

export default function plugin(API) {
    API.stickyElement = (selector) => {
        let stickyElement = document.querySelector(selector);
        let originalElementPosition = stickyElement.offsetTop;
        let setElementOffset = stickyElement.offsetHeight;
        let bodyElement = document.querySelector(
            'body:not(.template-page-landing)'
        );

        const stickIt = () => {
            let scrollPosition = Math.round(window.scrollY);

            if (
                scrollPosition >
                originalElementPosition + setElementOffset - 20
            ) {
                stickyElement.classList.add('sticky');
                document.querySelector('html').classList.add('sticky');
                if (bodyElement)
                    bodyElement.style.paddingTop = `${setElementOffset}px`;
            } else {
                stickyElement.classList.remove('sticky');
                document.querySelector('html').classList.remove('sticky');
                if (bodyElement) bodyElement.style.paddingTop = 0;
            }
        };

        let scrollIntervalID = setInterval(stickIt, 400);
    };
}
